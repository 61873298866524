<template>
  <div class="layout_common modelNum">
    <div class="main_box">
      <div class="main_box_con">
        <searchToChargeback
          :detailPageQuery="false"
          @search="search"
          @reset="search"
        />
        <div
          class="echart"
          :style="{ height: echartHeight + 'px' }"
          v-loading="loading"
        >
          <div class="bar">
            <barEchart :propData="echartBarData" />
          </div>
          <ul class="pie store" v-show="!storeId">
            <div class="title">商家</div>
            <li v-for="(item, index) in merchantList" :key="index">
              <pieEchart
                radius="80%"
                :center="['20%', '50%']"
                :id1="`model_pie1_box${index}`"
                :id2="`model_pie1_echart${index}`"
                :propData="item"
              />
            </li>
            <notData v-if="merchantList.length == 0" />
          </ul>
          <ul class="pie">
            <div class="title">退机原因</div>
            <li v-for="(item, index) in reasonList" :key="index">
              <pieEchart
                radius="80%"
                :id1="`model_pie2_box${index}`"
                :id2="`model_pie2_echart${index}`"
                :propData="item"
              />
            </li>
            <notData v-if="reasonList.length == 0" />
          </ul>
        </div>
        <div class="pagination" v-if="total > 10">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="curPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            >>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchToChargeback from '@/views/data-center/components/searchToChargeback'
import barEchart from './bar'
import pieEchart from './pie'
import { chargebackModel } from '@/api'
import { formatDate, diffDate } from '@/utils/common'
export default {
  components: {
    barEchart,
    pieEchart,
    searchToChargeback
  },
  props: {
    // 来区分 周期类型 1：按报修时间 2:按发货日期
    cycleType: {
      type: Number,
      default: 1
    },
    // 那个组件引用的
    compType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      isShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      timeType: 3,
      type: 1,
      level: '',
      cityId: '',
      durationType: '',
      chargebackCategory: '',
      chargebackType: '',
      reason: '',
      echartBarData: [],
      merchantList: [],
      reasonList: []
    }
  },
  computed: {
    echartHeight () {
      return this.merchantList.length * 150
    }
  },
  mounted () {
    this.endTime = formatDate(new Date()).slice(0, 7)
    this.startTime = diffDate(-5, 'months', true).slice(0, 7)
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        level: this.level,
        cityId: this.cityId,
        type: this.type,
        timeType: this.timeType,
        storeId: this.storeId,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        start: this.startTime,
        end: this.endTime
      }
      this.loading = true
      chargebackModel(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.total = totalCount
        this.echartBarData = list.map(item => ({
          name: item.commodityName,
          value: item.rentWithdrawalNum,
          rentInNum: item.rentInNum
        }))
        this.merchantList = list.map(item => item.store)
        this.reasonList = list.map(item => item.reason)
      })
    },
    search (val) {
      this.curPage = 1
      const {
        timeType,
        startTime,
        endTime,
        level,
        cityId,
        storeId,
        type,
        durationType,
        chargebackCategory,
        chargebackType,
        reason
      } = val
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.type = type
      this.level = level
      this.cityId = cityId
      this.storeId = storeId
      this.durationType = durationType
      this.chargebackCategory = chargebackCategory
      this.chargebackType = chargebackType
      this.reason = reason
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.modelNum {
  .echart {
    display: flex;
    margin-top: 20px;
    height: 1500px;
    .bar {
      flex: 1.5;
    }
    .pie {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      height: 100%;
      &.store {
        flex:1.5
      }
      .title {
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        font-size: 16px;
      }
      li {
        flex: 1;
        padding: 5px 0;
      }
    }
  }
  .pagination {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
